







import {Component, Vue} from 'vue-property-decorator';
import RemindPasswordForm from '@/components/RemindPasswordForm.vue';
import IRemindPasswordForm from '@/types/IRemindPasswordForm';
import AuthService from '@/services/AuthService';

@Component({
  name: 'RemindPassword',
  components: {RemindPasswordForm},
  // components: {LoginForm}
})
export default class RemindPassword extends Vue {
  remindPassword(data: IRemindPasswordForm) {
    return AuthService.remindPassword(data);
  }
}
