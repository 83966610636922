
































import {Component, Prop, Vue} from 'vue-property-decorator';
import {email, required} from '@/utils/validationRules';
import IRemindPasswordForm from '@/types/IRemindPasswordForm';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({name: 'RemindPasswordForm'})
export default class RemindPasswordForm extends Vue {
  @Prop()
  onSubmit!: Function;

  form: IRemindPasswordForm = {
    mail: '',
  };
  rules: object = {
    mail: [required, email],
  };
  loading: boolean = false;

  async submit() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        await this.onSubmit(this.form);
        notify(NotificationTypes.success, this.$t('SYSTEM_MESSAGE_HAS_BEEN_SENT'), this.$t('SYSTEM_CHECK_YOUR_EMAIL_TO_RESTORE_PASSWORD'));
        await this.$router.push({name: 'Login'});
      } catch (e) {
        resolveError(e, 'restore_password');
      } finally {
        this.loading = false;
      }
    }
  }
}
